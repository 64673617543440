.container {
  display: flex;
  flex-direction: row;

  .header {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    flex-shrink: 0;
  }

  .headerOverlay {
    position: absolute;
    bottom: -1px; /* covers potential gap between next element */
    width: 100%;
    height: 100%;

    & > div {
      user-select: none;
    }
  }

  .contentContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .content {
    display: flex;
    flex-flow: column wrap;
    flex-grow: 1;
    justify-content: center;
    background-color: var(--anti-flash-white);
    color: var(--secondary-color-5);
    padding: 0 24px;
  }

  .buttons {
    display: flex;
    background-color: var(--anti-flash-white);
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 16px 24px 1.5rem;
    user-select: none;

    .button {
      height: 40px;
      border-radius: 56px;

      > span {
        word-break: break-word;
      }
    }

    .button:nth-child(1) {
      &:not([disabled]) {
        > span {
          color: var(--anti-flash-white);
        }
      }
    }

    .button:nth-child(2) {
      background-color: var(--anti-flash-white);
      border: 1px solid lightgray;

      > span {
        color: var(--ink-med);
      }

      &:hover {
        background-color: rgb(0 0 0 / 3%);
      }

      &:active {
        background-color: rgb(0 0 0 / 6%);
      }
    }
  }

  &:global(.fullScreen) {
    flex-direction: column;
    height: 100%;

    .header {
      width: 100%;
    }

    .buttons {
      height: auto;
      padding: 12px 24px 16px;

      .button {
        display: block;
        margin-right: 0;
      }
    }
  }
}

:global(.legacyUi).container {
  .content {
    background-color: var(--secondary-color-5);
    color: var(--white);
    padding: 20px 24px 0;
    flex-grow: 1;
  }

  .buttons {
    background-color: var(--secondary-color-5);
    padding-bottom: 16px;
    margin-top: auto;

    .button {
      border-radius: 8px;

      > span {
      }
    }

    .button:nth-child(1) {
      &:not([disabled]) {
        > span {
          color: var(--secondary-color-5);
        }
      }
    }

    .button:nth-child(2) {
      background-color: var(--ink-med);
      border: none;

      > span {
        color: white;
      }

      &:hover {
        background-color: var(--ink-med-dark);
      }

      &:active {
        background-color: var(--ink-extra-light);
      }
    }
  }
}
