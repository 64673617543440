@mixin adCountdownText() {
  font-size: var(--font-size-3);
  line-height: 21px;
  color: var(--anti-flash-white);
}

.videoAdDialogBody {
  margin: 0 !important;
  padding: 18px 0 !important;
  box-sizing: content-box !important;
  border-width: 0 !important;
  border-style: solid !important;
  border-color: var(--black) !important;
  max-height: none !important;
  transition:
    width 750ms,
    border 750ms !important;
  background-color: var(--black) !important;
}

.videoAdTopMessageContainer {
  display: flex;
  justify-content: center;
}

.videoAdMediaButtonsContainer {
  position: absolute;
  left: 8px;
}

.videoAdCountdownContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
}

.countdownText {
  @include adCountdownText;

  font-weight: 400;
  text-align: center;
}

.stationTitle {
  @include adCountdownText;

  font-weight: 600;
}

.topOfAdMessage {
  font-size: var(--font-size-4);
  color: var(--anti-flash-white);
  font-weight: 500;
  line-height: 24px;
}

.videoAdDialogContent {
  min-width: 320px;
  min-height: 240px;
  transition: 750ms !important;
  background-color: var(--black);

  @media (--min-width-small) {
    border-radius: 12px !important;
  }
}

.videoAdSlot * {
  transition: width 750ms;
}
