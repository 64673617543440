.dialogContent {
  width: 625px !important;
  max-width: 625px !important;
  height: 460px !important;
  border-radius: 16px;
}

.dialogBody {
  max-width: none;
  max-height: none !important;
  padding: 0 !important;
  overflow: hidden !important;
}

.container {
  height: 460px;

  .header {
    width: 250px;

    .headerOverlay {
      background: linear-gradient(
        to bottom,
        transparent,
        var(--secondary-color-5)
      );
    }

    .title {
      position: absolute;
      color: white;
      font-size: var(--font-size-6);
      line-height: 28px;
      bottom: 0;
      margin: 0 16px 12px;
    }
  }

  .contentContainer {
    .content {
      padding: 24px 24px 0;
      overflow-y: scroll;
      scrollbar-width: none; /* hide scrollbars on Firefox */
      -ms-overflow-style: none; /* hide scrollbars on Internet Explorer 10+ */

      &::-webkit-scrollbar {
        display: none; /* hide scrollbars on Safari and Chrome */
      }

      .containerItems {
        width: 100%;
        overflow-y: scroll;

        > div {
          margin-bottom: 0;
        }
      }
    }

    .buttons {
      flex-direction: row-reverse;
      border-top: 1px solid var(--ink-med-dark);

      .cancelButton {
        margin-right: 16px;

        &:hover {
          background-color: var(--ink-med-dark);
        }

        &:active {
          background-color: var(--ink-extra-light);
        }
      }
    }
  }

  &:global(.fullScreen) {
    padding-bottom: 50px; /* offset so that lower button isn't completely covered by Branch banner */

    .header {
      height: 204px;

      .title {
        margin: 0 24px 12px;
      }
    }

    .contentContainer {
      height: calc(100% - 204px);
      border-top: 1px solid var(--ink-med-dark);

      .buttons {
        flex-direction: column;

        .followButton {
          margin-bottom: 16px;
        }

        .cancelButton {
          margin-right: 0;
        }
      }
    }
  }
}
