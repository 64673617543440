.contentWrapper {
  overflow: hidden;
  margin: auto;

  .alexaImage {
    background-image: url('/assets/img/devices/alexa.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 176px;
    height: 88px;
    margin: 20px auto;

    @media (--min-width-medium) {
      width: 246px;
      height: 124px;
    }
  }

  .title {
    width: 100%;
    font-size: var(--font-size-3);
    color: var(--secondary-color-5);
    text-align: left;

    @media (--min-width-medium) {
      font-size: 26px;
    }
  }

  .subtitle {
    width: 100%;
    font-size: var(--font-size-1);
    color: var(--secondary-color-5);
    text-align: left;
    margin-top: 4px;
    line-height: 1.5;

    @media (--min-width-medium) {
      font-size: 15px;
    }
  }

  .button {
    margin-top: 20px;
    font-size: var(--font-size-3);
    line-height: 1.13;
  }
}

.closeIcon {
  top: 22px;
  right: 22px;
}
