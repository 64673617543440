.text {
  color: var(--secondary-color-5);
  font-size: 27px;
  line-height: 1.14;
  margin-bottom: 5px;
  margin-top: 10px;
}

.dismissButton {
  width: 180px;
  height: 40px;
  border-radius: 8px;
  margin-top: 21px;

  & > span {
    font-size: var(--font-size-3);
    line-height: 1.13;
    color: var(--white);
  }
}
