.dialogStyle {
  position: fixed;
  bottom: calc(var(--player-height-small) + 1rem);
  right: 16px;
  width: 360px;
  height: 192px;
  left: auto;
  top: auto;
  z-index: var(--top-z-index);
  border-radius: 2px;
  background-color: var(--premium-gold-light);

  h1 {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-6);
    line-height: 1.04;
    color: var(--secondary-color-5);
    font-weight: 600;
  }

  h3 {
    font-size: var(--font-size-2);
    font-weight: 500;
    margin-top: 6px;
  }
}

.dialogStyleWithoutPlayer {
  bottom: 16px;
}

.dialogStyleWithOneTrustFloatingButton {
  right: 5rem;
}

.dialogContents {
  margin-left: 20px;
}

.line1 {
  display: block;
  max-width: 234px;
}

.line2 {
  max-width: 264px;
}

.input {
  display: inline-block;
  border-radius: 2px;
  background-color: var(--content-area-background-color-hex);
}

.phoneIcon {
  width: 32px;
  height: 63px;
  border-radius: 0.5px;
}

.phoneIconContainer {
  display: inline-block;
  margin-right: 14px;
}

.phoneAndTextContainer {
  margin-top: 19px;
  margin-bottom: 20px;
}

.textContainer {
  display: inline-block;
  max-width: 264px;
}

.submit {
  display: inline-block;
  position: absolute;
  width: 56px;
  height: 48px;
  border-radius: 2px;
  margin-left: 16px;

  span {
    font-size: 19px;
    color: var(--content-area-background-color-hex);
  }
}

.closeButton {
  top: 16px;
  right: 16px;
}

.button {
  width: 148px;
  height: 48px;
  border-radius: 2px;
  display: inline-block;
  background-color: var(--secondary-color-5);

  span {
    font-size: 19px;
    color: var(--content-area-background-color-hex);
  }
}

.leftButton {
  background-color: var(--ink-light);
}

.rightButton {
  margin-left: 16px;
}

.buttonsContainer {
  display: inline-block;
}

.legalText {
  display: block;
  margin-top: 6px;
  margin-left: 1px;
  font-size: var(--font-size-2);
  color: var(--secondary-color-5);
}

.error {
  color: var(--error-color-2);
}
