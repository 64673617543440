.authDialogBody {
  padding: 0 !important;
  background-color: var(--content-area-background-color-hex) !important;
}

.authDialogContent {
  border-radius: 15px;
  max-width: 670px !important;

  @media (--max-width-small) {
    width: 98% !important;
  }
}

.authDialogFormContainer {
  background-color: var(--content-area-background-color-hex);

  @media (--min-width-large) {
    padding: 34px 56px;
  }
}

.closeIcon {
  display: block;
  width: 30px;
  height: 30px;
  background: url('/assets/img/shared/close-large.svg');
}
