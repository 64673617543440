.dialogContent {
  width: 625px !important;
  max-width: 625px !important;
  height: 375px !important;
  border-radius: 16px;
}

.dialogBody {
  max-width: none;
  max-height: none !important;
  padding: 0 !important;
  overflow: hidden !important;

  &.fullScreen {
    max-width: 1024px;
    margin: auto;
    border-radius: 0;
  }
}
