$rightSideWidth: 330px;

.dialog {
  /* material-ui puts a max-height on dialog content. This overrides it */

  /* so the dialog can be full screen. */
  max-height: 100% !important;
  height: 100%;
  background-color: var(--secondary-color-5);
  padding: 0 !important;
  max-width: none;

  :global(html.discord) & {
    background-color: var(--dark-grey) !important;
  }
}

.dialogContent {
  top: 0;
  height: 100%;

  /*
    material-ui hack so Dialog covers entire screen
  */
  position: absolute !important;
  width: 100% !important;
  transform: none !important;
  max-width: none !important;

  & > div {
    height: 100%;
  }
}

.dialogOverlay {
  height: calc(100% - var(--player-height)) !important;
}

.containerPage {
  display: flex;
  width: 100vw;
  height: 100%;

  :global(.infoBannerVisible) & {
    padding-top: var(--info-banner-height-baseline);
  }

  .badge {
    position: absolute;
    top: 16px;
    left: 20px;

    @media (--min-width-xxsmall) {
      display: none;
    }
  }

  .scrollReset {
    height: 100%;
    overflow-y: auto;
    width: 100%;

    .containerItems {
      position: relative;
      max-width: 100vw;
      padding: 0 20px;
      overflow: hidden;

      :global(html.discord) & {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100vh;

        @media (--min-width-discord-picture-in-picture-medium) {
          height: auto;
        }
      }

      :global(html.discord:not(.mobile)) & {
        padding: 0 var(--discord-content-y-padding);

        @media (--min-width-xxsmall) {
          padding: 0 50px var(--discord-content-y-padding);
          display: block;
          flex-direction: unset;
          justify-content: unset;
        }
      }

      :global(html.ford:not(.mobile)) & {
        padding: 0 var(--discord-content-y-padding);

        @media (--min-width-xxsmall) {
          padding: 0 16px var(--discord-content-y-padding);
        }
      }
    }
  }

  .leftSide {
    width: 100vw;
    height: 100%;

    @media (--min-width-medium) {
      &.desktop {
        width: calc(100vw - #{$rightSideWidth});
        padding-right: 15px;
      }
    }
  }

  .rightSide {
    display: none;

    @media (--min-width-medium) {
      display: initial;
      position: fixed;
      width: $rightSideWidth;
      background-color: var(--ink-dark);
      right: 0;
      height: 100vh;
      padding: 0 15px;
      padding-top: 15px;
    }

    .ad {
      width: 300px;
      cursor: pointer;
      overflow: hidden;
      background-color: var(--black);
      margin: 5px;
      padding-top: 15px;
    }

    .recents {
      padding-top: 15px;
    }

    .noRecentsSubtitle {
      color: white;
      font: var(--primary-font-semi-bold);
      margin-top: 10px;
    }
  }
}
