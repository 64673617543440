.dialogBody {
  height: 100vh;

  /* material-ui puts a max-height on dialog content. This overrides it */

  /* so the dialog can be full screen. */
  max-height: none !important;
  background-color: var(--secondary-color-5);

  .downloadIconWrapper {
    background-color: rgb(255 255 255 / 10%);
    height: 48px;
    width: 48px;
    margin: 0 auto 20px;
    border-radius: 50%;
    position: relative;
  }

  .downloadIcon {
    height: 16px;
    width: 16px;
    top: 15px;
    margin: 0 auto;
    position: relative;
    color: white;
    text-decoration: none;
    background-image: url('/assets/img/shared/download-white.svg');
    background-repeat: no-repeat;
  }

  .closeButton {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 1;
    font-size: var(--font-size-3);
    cursor: pointer;
    color: white;
  }

  .innerContent {
    margin: 50% auto;
    max-width: 264px;
    text-align: center;

    .playCircle {
      margin-bottom: 16px;
    }

    h1 {
      color: white;
      font-weight: 600;
      margin-bottom: 16px;
    }

    .continueListening {
      background-color: white;
      height: 48px;

      span {
        color: var(--secondary-color-5);
      }
    }
  }
}
