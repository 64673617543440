$dialogContentWidth: 68%;
$dialogWidth: 330px;

@mixin ctaButtonTextStyles() {
  font-size: var(--font-size-1) !important;
  line-height: 16px;
  font-weight: bold;
  color: var(--secondary-color-5);
  letter-spacing: var(--letter-spacing-loose);
  text-transform: uppercase;
}

@mixin ctaButtonStyles() {
  border: 1px solid var(--secondary-color-5);
  border-radius: 42px;
}

.dialogBody {
  max-height: none !important;
  padding: 0 !important;
  background-color: var(--content-area-background-color-hex) !important;
}

/* center the dialog vertically regardless of any styles that material-ui adds */
.dialogContent {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  border-radius: 15px;
  max-width: 670px !important;

  @media (--max-width-small) {
    width: 98% !important;
  }
}

/* also center the dialog horizontally on desktop */
.desktopDialogContent {
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.mobileDialogContent {
  width: auto !important;
  margin: 0 8px !important;
}

.content {
  padding: 30px 10px;

  @media (--min-width-small) {
    padding: 40px 80px;
  }

  @media (--min-width-large) {
    padding: 70px 120px;
  }
}

.creativeWrapper {
  border-radius: 9px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--min-width-xsmall) {
    width: $dialogWidth;
    margin: auto;
  }
}

.creativeSubtext {
  width: $dialogContentWidth;
  margin: auto;
  margin-bottom: 20px;
  font-size: var(--font-size-1);
  line-height: 16px;
}

.linkWithAlexa {
  background-color: var(--carolina-blue);
}

.goPremium {
  background-color: var(--premium-gold-light);
}

.premiumBadgeWithText {
  margin-top: 42px;
  margin-bottom: 32px;
}

.goPremiumImage {
  width: 100%;
  margin-bottom: 20px;
}

.alexaImage {
  width: 40%;
  margin-top: 30px;
}

.userDialogCtaButton {
  width: $dialogContentWidth;
  display: flex;
  justify-content: center;
  padding: 10px 25px;
  margin: auto;
  margin-bottom: 30px;
  height: 38px !important;

  @include ctaButtonStyles;

  > span {
    @include ctaButtonTextStyles;
  }

  &:hover {
    background-color: var(--elephant-grey);
    border: none;

    > span {
      color: var(--secondary-color-5);
    }
  }

  &:active {
    background-color: var(--grey-poupon);
    border: none;
  }
}

.headerContainer {
  text-align: center;

  .heading {
    font-size: 54px;
    line-height: 48px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
    color: var(--secondary-color-5);
  }

  .subHeading {
    font-size: var(--font-size-2);
    line-height: 19px;
    margin: auto;
    margin-bottom: 25px;
    width: $dialogWidth;
    color: var(--secondary-color-5);
  }
}

.goToContentButton {
  height: 54px;
  border-radius: 54px;
  max-width: $dialogWidth;
  margin: auto;
  margin-top: 45px;
}

.closeIcon {
  display: block;
  width: 30px;
  height: 30px;
  background: url('/assets/img/shared/close-large.svg');
}
