.buttonContainer {
  text-align: center;

  svg {
    cursor: pointer;
    margin: auto;
  }
}

.messageContainer {
  padding: 20px;
  font-size: var(--font-size-5);
  line-height: 1.5;
}
