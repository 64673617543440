.container {
  height: 375px;
  background-color: var(--anti-flash-white);

  .header {
    width: 352px;

    .title {
      font-size: var(--font-size-6);
      line-height: 28px;
      bottom: 0;
    }
  }

  .contentContainer {
    flex-direction: column;
    justify-content: flex-end;

    .buttons {
      flex-direction: column;
      padding-top: 0;

      .submitButton {
        margin-bottom: 16px;
      }
    }
  }

  .title {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: var(--font-size-6);
    line-height: 28px;
  }

  .subtitle {
    font-size: var(--font-size-3);
    line-height: 22px;
  }

  &:global(.fullScreen) {
    padding-bottom: 50px; /* offset so that lower button isn't completely covered by Branch banner */

    .header {
      height: auto;
      flex-grow: 1;

      &:global(.nfl) {
        background-position: right 50% bottom 75%;

        @media (min-height: 600px) {
          background-position: right 50% bottom 60%;

          @media (min-aspect-ratio: 3 / 5) {
            background-position: right 50% bottom 75%;
          }
        }

        @media (min-height: 700px) {
          background-position: right 50% bottom 60%;

          @media (min-aspect-ratio: 5 / 7) {
            background-position: right 50% bottom 75%;
          }
        }

        @media (min-height: 800px) {
          background-position: right 50% bottom 60%;

          @media (min-aspect-ratio: 3 / 4) {
            background-position: right 50% bottom 75%;
          }
        }
      }
    }

    .headerOverlay {
      height: 90px;
      background: linear-gradient(
        to bottom,
        transparent,
        var(--secondary-color-5)
      );
    }

    .contentContainer {
      height: auto;

      .content {
        margin-top: 32px;
        padding-top: 0;
        padding-bottom: 32px;
      }
    }
  }
}

:global(.legacyUi).container {
  background-color: transparent;

  .contentContainer {
    justify-content: flex-start;

    .content {
      margin: 0 0 32px;
    }
  }
}
